// External packages
import * as React from "react"
import GatsbyLink from "gatsby-link"

// Components
import { UiLink, UiLinkProps } from "./ui/Link"

export const nextJsPages = [
  "/welcome-to-teklas-official-website-b-version",
  "/do-not-touch-site-will-break",
  "/store",
  "/jamie-xx-tekla",
  "/winter-terry",
  "/holiday-collection",
  "/gift-shop",
  "/",
  "/inspiration/bathroom",
  "/inspiration/bedroom",
  "/inspiration/sleepwear",
  "/testing-traceability",
]

export type LinkProps<
  To extends string | undefined = undefined,
  Href extends string | undefined = undefined
> = {
  to?: To
  href?: Href
} & Omit<
  To extends undefined
    ? Href extends undefined
      ? UiLinkProps<"button">
      : UiLinkProps<"a">
    : UiLinkProps<typeof GatsbyLink<{}>>,
  "as"
>

export const Link = <
  To extends string | undefined = undefined,
  Href extends string | undefined = undefined
>(
  props: LinkProps<To, Href>
) => {
  if (nextJsPages.includes(props.to) || props.to?.startsWith("/product/")) {
    return <UiLink {...props} as="a" href={props.to} />
  }

  if (props.to && /^\/(?!\/)/.test(props.to)) {
    return <UiLink {...props} as={GatsbyLink} />
  }

  if (!props.to && !props.href) {
    return <UiLink {...props} as="button" />
  }

  if (props.href) {
    return <UiLink {...props} as="a" href={props.href} />
  }

  return <UiLink {...props} as="a" href={props.to} />
}
